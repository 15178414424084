import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Title from "../components/Title"
import Image from "gatsby-image"
// ...GatsbyImageSharpFluid

const AboutPage = () => {
  return (
    <Layout>
      <h2>about page</h2>
      <h3>you really wanna know huh?</h3>
      <p>
        Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quasi nobis
        quam repellat sit laudantium. Harum quaerat, reprehenderit eligendi
        necessitatibus excepturi amet porro eos, aliquid beatae molestias
        obcaecati sed maxime cumque?
        <a href="#">oh my god</a>
      </p>
    </Layout>
  )
}

export default AboutPage
